import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Banners, Cards, HomeHero, MemberSections } from "../../../components/home"

import NewsletterModule from "../../../components/flexibleContent/NewsletterModule"

export default function HomeTemplate(props) {
  const {
    data: {
      page: {
        templateHome: {
          banners,
          cards,
          heroButton,
          heroHeadline,
          heroImage,
          heroImageCaption,
          heroTagline,
          memberSections,
          newsletterHeadline
        }
      }
    }
  } = props

  return (
    <Container>
      <HomeHero
        heroHeadline={heroHeadline}
        heroTagline={heroTagline}
        heroImage={heroImage}
        heroButton={heroButton}
        heroImageCaption={heroImageCaption}
      />
      <Banners banners={banners} />
      <Cards cards={cards} />
      {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && (
        <NewsletterModule headline={newsletterHeadline} newsletterType="home" />
      )}
      <MemberSections sections={memberSections} />
    </Container>
  )
}

const Container = styled.div``

export const CollectionQuery = graphql`
  query HomeTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      uri
      templateHome {
        banners {
          alignCard
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, layout: FULL_WIDTH)
              }
            }
          }
          button {
            target
            title
            url
          }
          headline
          imageCaption
          text
        }
        cards {
          button {
            target
            title
            url
          }
          buttonIcon
          headline
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
              }
            }
          }
          text
        }
        heroTagline
        heroHeadline
        heroButton {
          target
          title
          url
        }
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH)
            }
          }
        }
        heroImageCaption
        homeHideSearch
        newsletterHeadline
        newsletterSubheadline
        memberSections {
          members {
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 145, layout: FIXED)
                }
              }
            }
            link {
              target
              title
              url
            }
          }
          membersBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, layout: FULL_WIDTH)
              }
            }
          }
          membersHeadline
        }
      }
    }
  }
`
